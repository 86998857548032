<template>
  <div class="userPullDown">
    <el-dropdown  @command="handleCommand">
            <span class="el-dropdown-link">
              <slot></slot>
            </span>
      <el-dropdown-menu slot="dropdown">
<!--        <el-dropdown-item command="userInfo" icon="el-icon-user">个人信息</el-dropdown-item>-->
        <el-dropdown-item command="updatePass" icon="el-icon-edit">修改密码</el-dropdown-item>
        <el-dropdown-item command="logout" icon="el-icon-switch-button">退出登录</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <updatePassword ref="updatePassword"></updatePassword>
  </div>
</template>

<script>
import * as u from "@/util/util";
import * as c from "@/util/config";
export default {
  name: "loginElement",
  components: {
    'updatePassword': () => import('@/components/user/updatePassword.vue'),
  },
  data(){
    return{
      //用户信息
      userInfo: u.getSession(c.sessionKey.USER_INFO),
    }
  },
  created(){

  },
  methods: {
    //下拉菜单触发时间
    handleCommand(command) {
      switch (command) {
        case 'updatePass':
          this.$refs.updatePassword.openDia();
          break
        case 'userInfo':
          break
        case 'logout':
          this.$confirm('是否确认退出登录?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.$HttpService.post("/sys-login/logout").then(res => {
              this.$util.clearSession();
              //清空顶部导航
              this.$store.dispatch("closeAll")
              this.$router.push('/login')
            })
          })
          break
      }
    },
  }
}
</script>


<style scoped lang="scss">
  .userPullDown{

  }
</style>