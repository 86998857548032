<template>
  <div class="box">
    <avue-crud :ref="ref"
               v-model="form"
               :data="tableData"
               :table-loading="tableLoading"
               :option="tableOption"
               :before-open="beforeOpen"
               @on-load="getList"
               @search-change="searchChange"
               @refresh-change="refreshChange"
               @size-change="sizeChange"
               @current-change="currentChange"
               @row-update="handleUpdate"
               @row-save="handleSave"
               @row-del="rowDel">
        <template slot-scope="scope" slot="menuLeft">
          <el-button v-if="buttonPermissionsCheck('SYS_DEPT_ADD')" type="primary" icon="el-icon-plus" :size="scope.size" @click="handleAdd(null)">新增</el-button>
        </template>
          <template slot="menu" slot-scope="scope">
            <el-button v-if="buttonPermissionsCheck('SYS_DEPT_ADD')" :size="scope.size" @click="handleAdd(scope.row)" type="text">
              添加
            </el-button>
         </template>
    </avue-crud>

  </div>
</template>

<script>
    import {tableOption} from '@/js/sys/dept/list.js'
    import tableMixins from "@/mixins/tableMixins.vue"

    export default {
        name: "sys_dept_list",
        mixins: [tableMixins],
        data() {
            return {
                //访问接口前缀
                url: "sys-dept",
                //重新设置列表访问接口
                listUrl:"findTreeAuth",
                // 表格参数
                tableOption: tableOption,
                //弹框显示判断
                dialogVisible: false,
            }
        },
        created() {
        },

        methods: {


          /**
           * 自定义新增
           */
          handleAdd(row) {
            this.obj=row;
            this.$refs.crud.rowAdd();
          },


          //弹框打开前的回调
          beforeOpen(done,type) {
            setTimeout(res=>{
                if(type=='add' &&this.obj!=null){
                    this.form.parentId=this.obj.deptId;
                }
            },50)
            done();

          },

            /**
           * 新增保存
           * @param row 行数据
           * @param done 完成回调
           * @param loading 加载回调
           */
          handleSave: function (row, done, loading) {
            if(this.obj!=null){
              row.parentId=this.obj.deptId;
            }
            this.$HttpService.post("/" + this.url + "/" + this.addUrl, row).then(res => {
              this.$message.success('新增成功');
              this.getList();
              done();
            }).catch(() => {
              loading();
            })


          },

          /**
           * 获取列表数据
           * @param page 分页参数
           * @param params 查询条件
           */
          getList(page, params) {
            this.tableLoading = true;
            let query = Object.assign({}, this.searchForm, this.page);
            this.$HttpService.get("/" + this.url + "/" + this.listUrl, query).then(res => {
              this.tableData = res;
              this.tableLoading = false;
            }).catch(() => {
              this.tableLoading = false;
            })
          },

        }
    }
</script>

<style scoped>

</style>
