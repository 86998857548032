import * as u from '@/util/util'

export const tableOption = {
  border: true,
  index: true,
  indexLabel: '序号',
  indexWidth: 50,
  stripe: true,
  align: 'center',
  searchMenuSpan: 6,
  labelWidth: 120,
  height: '450px',
  calcHeight: 400,
  dialogWidth: 900,
  menuWidth: 300,
  menuAlign: 'center',
  addBtn: u.buttonPermissionsCheck("SYS_USER_ADD"),
  editBtn: u.buttonPermissionsCheck("SYS_USER_UPDATE"),
  delBtn: u.buttonPermissionsCheck("SYS_USER_DEL"),
  column: [
    {
      prop: 'id',
      label: 'ID',
      hide: true,
      addDisplay: false,
      editDisplay: false
    }, {
      prop: 'username',
      label: '用户名',
      type: 'input',
      overHidden: true,
      minWidth: 60,
      maxlength: 200,
      span: 24,
      search: true,
      rules: [{
        required: true,
        message: '请输入',
        trigger: 'blur'
      }]
    },
    {
      prop: 'name',
      label: '姓名',
      type: 'input',
      overHidden: true,
      minWidth: 60,
      maxlength: 200,
      span: 24,
      search: true,
      rules: [{
        required: true,
        message: '请输入',
        trigger: 'blur'
      }]
    },
    {
      prop: 'deptId',
      label: '所属部门',
      type: 'tree',
      overHidden: true,
      minWidth: 60,
      span:24,
      dicUrl:"/sys-dept/findTreeAuth",
      props: {
        label: "deptName",
        value: "deptId",
      },
    },
    {
      prop: 'state',
      label: '是否启用',
      type: 'select',
      slot:true,
      overHidden: true,
      span:24,
      dicUrl:"/common/getItem/state",
      rules: [{
        required: true,
        message: '请输入',
        trigger: 'blur'
      }]
    },
    {
      prop: 'roleNames',
      label: '角色',
      type: 'select',
      multiple:true,
      overHidden: true,
      minWidth: 60,
      maxlength: 200,
      addDisplay: false,
      editDisplay: false,
      span: 24,
      search: true,
      dicUrl:"/sys-role/findList",
      props:{
        label:"name",
        value:"name",
      },
      rules: [{
        required: true,
        message: '请输入',
        trigger: 'blur'
      }]
    },
  ]
}



