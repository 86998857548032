<template>
  <div id="app">
    <el-card class="box-card">
      <div class="box_top">
        <h2>HT文件快传</h2>
      </div>
      <div class="tabs">
        <el-radio-group v-model="typeOperation" size="mini">
          <el-radio-button label="login">登录</el-radio-button>
          <el-radio-button label="register">注册</el-radio-button>
        </el-radio-group>
      </div>
      <loginElemnt v-if="typeOperation==='login'"></loginElemnt>
      <registerElement v-if="typeOperation==='register'"></registerElement>

    </el-card>
  </div>
</template>

<script>
import * as u from '@/util/util'
import * as c from '@/util/config'

export default {
  name: "login",
  components: {
    'loginElemnt': () => import('@/components/auth/loginElement.vue'),
    'registerElement': () => import('@/components/auth/registerElement.vue'),
  },
  data() {
    return {
      //操作类型-登录-注册
      typeOperation:"login",
    }
  },
  created() {
  },

}
</script>

<style scoped lang="scss">
#app {
  width: 100%;
  height: 100%;
  background: url('../assets/loginBg.jpg');
  background-size: 100%;
}

.box_top {
  text-align: center;
  padding: 30px;
}

.box-card {
  width: 500px;
  height: 450px;
  padding: 20px;
  margin: 0 auto;
  margin-top: 50px;
  border-radius: 30px;

  .tabs{
    text-align: center;
    margin-bottom: 20px;
  }
  .three_login {
    border-top: 1px solid #dddddd;
    margin-top: 10px;
    padding-top: 10px;
    display: flex;
    width: 100%;

    .group {
      margin: 0 auto;

      span {
        font-size: 12px;
      }

      .el-avatar {
        background: white;
      }
    }

    .group:hover {
      span {
        color: #a73e11;
        cursor: pointer;
      }
    }

  }
}

</style>
