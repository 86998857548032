<template>
  <el-dialog
      title="我的文件"
      :visible.sync="dialogVisible"
      width="80%"
      top="5vh"
      :before-close="handleClose"
      class="scroll-bar">
    <div class="fileDiv">
      <myFile></myFile>
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: "myFileDia",
  components: {
    'myFile': () => import('@/view/file/myFile.vue'),
  },
  data() {
    return {
      dialogVisible: false
    }
  },
  methods: {
    handleClose(done) {
      done();
    },
    openDia() {
      this.dialogVisible = true;
    }
  }
}
</script>


<style scoped lang="scss">
  /deep/ .el-dialog__header{
    display: none;
  }
  .fileDiv{
    width: 100%;
    height: 600px;
     overflow-y: auto;

  }
</style>