<template>
    <div class="box">
        <avue-crud :ref="ref"
                   v-model="form"
                   :page="page"
                   :data="tableData"
                   :table-loading="tableLoading"
                   :option="tableOption"
                   :before-open="beforeOpen"
                   @on-load="getList"
                   @search-change="searchChange"
                   @refresh-change="refreshChange"
                   @size-change="sizeChange"
                   @current-change="currentChange"
                   @row-update="handleUpdate"
                   @row-save="handleSave"
                   @row-del="rowDel">
            <template slot="menu" slot-scope="scope">
                <el-button v-if="buttonPermissionsCheck('SYS_SET_MENU')" :size="scope.size" @click="openMenu(scope.row)"
                           type="text">设置菜单
                </el-button>
            </template>
        </avue-crud>
        <el-dialog
                title="菜单"
                :visible.sync="dialogVisible"
                width="30%">
            <el-tree
                    check-strictly
                    v-if="dialogVisible"
                    ref="tree"
                    :data="menuList"
                    default-expand-all
                    @check-change="checkChange"
                    :props="defaultProps"
                    class="filter-tree"
                    node-key="id"
                    highlight-current
                    show-checkbox>
            </el-tree>
            <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="setMenuByRole">确 定</el-button>
      </span>
        </el-dialog>
    </div>
</template>

<script>
    import * as u from '@/util/util'
    import {tableOption} from '@/js/sys/role/list.js'
    import tableMixins from "@/mixins/tableMixins.vue"

    export default {
        name: "sys_role_list",
        mixins: [tableMixins],
        watch: {
            //监听数据权限变化
            "form.dataAuthority": function (val) {
                this.tableOption = this.$util.copyBean(tableOption);
                if (val == "99") {
                    this.tableOption.column.push(
                        {
                            prop: 'custom',
                            label: '自定义数据权限',
                            type: 'tree',
                            hide: true,
                            overHidden: true,
                            checkStrictly:true,
                            minWidth: 60,
                            span: 24,
                            multiple: true,
                            dicData:this.deptTree,
                            props: {
                                label: "deptName",
                                value: "deptId",
                            },
                        },
                    )
                }
            },
        },
        data() {
            return {
                //接口访问前缀
                url: "sys-role",
                //弹框回显
                dialogVisible: false,
                // 表格参数
                tableOption: tableOption,
                //菜单集合
                menuList: [],
                //选中
                checked: [],
                //部门树
                deptTree:null,
                defaultProps: {
                    children: 'children',
                    label: 'name'
                },
            }
        },
        created(){
            this.init();
        },
        methods: {

            //弹框打开前的回调
            beforeOpen(done,type){
                this.$HttpService.get(`/sys-role-auth/findCustomByRoleId/${this.form.id}`).then(res=>{
                    this.form["custom"]=res.map(x=>{
                        return x.deptId;
                    })
                    done();
                })
            },

            //菜单树选中变化
            checkChange(node, val) {
                if (val) {
                    this.setParent(node);
                } else {
                    this.setChildren(node.children, val);
                }
            },
            //设置子集状态
            setChildren(nodes, val) {
                nodes.map(x => {
                    if (x.children != null && x.children.length > 0) {
                        this.setChildren(x.children);
                    }
                    //设置值
                    this.$refs.tree.setChecked(x.id, val);
                })
            },
            //设置父级选中
            setParent(node) {
                //获取父节点
                let pNode = this.$refs.tree.getNode(node.pid);
                //递归
                if (pNode) {
                    this.setParent(pNode.data);
                }
                //设置选中
                this.$refs.tree.setChecked(node.id, true);
            },
            //保存菜单
            setMenuByRole() {
                let menuList = this.$refs.tree.getCheckedNodes(false, true)
                this.$HttpService.post("/sys-role/setMenuByRole", {
                    id: this.obj.id,
                    menus: menuList,
                }).then(res => {
                    this.$message.success("保存成功");
                    this.dialogVisible = false;
                })

            },
            //打开菜单设置框
            openMenu(row) {
                this.obj = row;
                //查询出所有菜单
                this.$HttpService.get("/sys-menu/findMenuTree").then(res => {
                    this.menuList = res;
                    this.tableLoading = false;
                    this.dialogVisible = true;
                    //查询出当前角色菜单
                    this.$HttpService.get("/sys-menu/findMenuRole/" + this.obj.id).then(x => {
                        //设置选中
                        for (let i = 0; i < x.length; i++) {
                            //设置选中
                            this.$refs.tree.setChecked(x[i].menuId, true);
                        }
                    })
                })
            },
            //初始化
            init(){
                //获取部门树
                this.$HttpService.get("/sys-dept/findTree").then(res=>{
                    this.deptTree=res;
                })
            },
        }
    }
</script>

<style scoped>

</style>
