<template>
  <div class="upload">
    <span>上传</span>
  </div>
</template>
<script>
export default {
  name: "upload"
}
</script>

<style scoped lang="scss">
    .upload{
    }
</style>