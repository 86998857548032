import * as u from '@/util/util'
import * as c from '@/util/config'

export const menu = {
  border: true,
  index: true,
  indexLabel: '序号',
  indexWidth: 50,
  stripe: true,
  align: 'center',
  searchMenuSpan: 6,
  labelWidth: 120,
  height: '450px',
  calcHeight: 400,
  dialogWidth: 900,
  menuWidth: 200,
  menuAlign: 'center',
  addBtn:false,
  editBtn: u.buttonPermissionsCheck("SYS_MENU_UPDATE"),
  delBtn: u.buttonPermissionsCheck("SYS_MENU_DEL"),
  column: [
    {
      prop: 'id',
      label: 'ID',
      hide: true,
      addDisplay: false,
      editDisplay: false
    },
    {
      prop: 'pid',
      label: '上级菜单',
      type: 'tree',
      hide: true,
      overHidden: true,
      maxlength: 200,
      minWidth: 50,
      defaultExpandAll:true,
      // dicUrl:"/sys-menu/findMenuTree",
      props: {
        label: "name",
        value: "id",
      },
    },
    {
      prop: 'name',
      label: '表单名称',
      type: 'input',
      align: 'left',
      overHidden: true,
      maxlength: 200,
      minWidth: 60,
      rules: [{
        required: true,
        message: '请输入',
        trigger: 'blur'
      }]
    },
    {
      prop: 'type',
      label: '菜单类型',
      type: 'radio',
      maxlength: 200,
      minWidth: 50,
      value:"0",
      overHidden: true,
      dicUrl:"/common/getItem/menu_type",
      rules: [{
        required: true,
        message: '请选择',
        trigger: 'blur'
      }]
    },
    {
      prop: 'code',
      label: '表单编码',
      type: 'input',
      overHidden: true,
      maxlength: 200,
      minWidth: 60,
      rules: [{
        required: true,
        message: '请输入',
        trigger: 'blur'
      }]
    }, {
      prop: 'icon',
      label: '图标',
      type: 'icon',
      iconList: c.iconList,
      overHidden: true,
      maxlength: 200,
      minWidth: 60,
    }, {
      prop: 'path',
      label: '菜单路径',
      type: 'input',
      addDisplay: false,
      editDisplay: false,
      maxlength: 200,
      overHidden: true
    },
    {
      prop: 'cache',
      label: '开启缓存',
      type: 'radio',
      addDisplay: false,
      editDisplay: false,
      overHidden: true,
      minWidth: 50,
      dicUrl:"/common/getItem/whether",
    },
    {
      prop: 'sort',
      label: '排序',
      type: 'number',
      overHidden: true,
      minWidth: 50,
    }
  ]
}

