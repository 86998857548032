<template>
  <div class="home_index">
    <div class="top">
      <div class="t-left"></div>
      <div class="t-right">
        <div class="menu myFile" @click="$refs.myFileDia.openDia()">我的文件</div>
        <div class="menu login" v-if="userInfo==null" @click="$router.push({path: '/login'})">登录</div>
        <div class="menu userInfo" v-else>
          <user-pull-down>
            {{ userInfo.name }}<i class="el-icon-arrow-down el-icon--right"></i>
          </user-pull-down>
        </div>
      </div>
    </div>
    <div class="tips">
      <el-card shadow="hover">
        <div class="t-text">上传违法违规的内容将承担法律责任!</div>
      </el-card>
    </div>
    <div class="items">
      <send class="item"></send>
      <accept class="item accept"></accept>
      <upload class="item upload"></upload>
    </div>
    <myFileDia ref="myFileDia"></myFileDia>
  </div>
</template>

<script>
import * as c from '@/util/config'
import * as u from '@/util/util'

export default {
  name: "index",
  components: {
    'user-pull-down': () => import('@/components/auth/userPullDown.vue'),
    'send': () => import('@/components/tool/send.vue'),
    'accept': () => import('@/components/tool/accept.vue'),
    'upload': () => import('@/components/tool/upload.vue'),
    'myFileDia': () => import('@/components/file/myFileDia.vue'),

  },
  data() {
    return {
      //用户信息
      userInfo: u.getSession(c.sessionKey.USER_INFO),
    }
  },
  methods: {
    //下拉菜单触发时间
    handleCommand(command) {
      switch (command) {
        case 'updatePass':
          this.$refs.updatePassword.openDia();
          break
        case 'userInfo':
          break
        case 'logout':
          this.$confirm('是否确认退出登录?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.$HttpService.post("/sys-login/logout").then(res => {
              this.$util.clearSession();
              //清空顶部导航
              this.$store.dispatch("closeAll")
              this.$router.push('/login')
            })
          })
          break
      }
    },
  }
}
</script>


<style scoped lang="scss">
.home_index {
  width: 100%;
  background: #fafafa;

  .top {
    width: 95%;
    margin: 0 auto;
    margin-top: 10px;
    display: flex;

    .t-left {
      flex: 1;
    }

    .t-right {
      width: 50%;
      display: flex;
      color: #29AAE3;
      font-size: 18px;
      font-weight: 900;
      justify-content: flex-end; /* 内容右对齐 */
      gap: 20px;

      .menu {
        border-radius: 10px;
        padding: 10px 20px;
        text-align: right;
        cursor: pointer;

        /deep/ .el-dropdown-link {
          color: #29AAE3;
          font-size: 18px;
          font-weight: 900;

        }
      }

      .menu:hover {
        background: #e8f0f9;
      }
    }
  }

  .tips {
    width: 300px;
    margin: 0 auto;
    background: white;

    /deep/ .el-card__body, .el-main {
      padding: 5px;
      text-align: center;
    }

    .t-text {
      color: #29AAE3;
      font-size: 15px;
      font-weight: 800;
    }
  }

  .items{
    margin-top: 30px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr; /* 单列布局 */
    justify-items: center;      /* 水平居中 */
    align-items: center;        /* 垂直居中 */
    .item{
      background: #29AAE3;
      color: white;
      text-align: center;
      width: 300px;
      height: 100px;
      line-height: 100px;
      border-radius: 30px;
      margin-top: 50px;
      cursor: pointer;
      box-shadow: 0 5px 20px rgba(65, 145, 245, .2);
      transition: box-shadow 0.3s ease; /* 平滑过渡效果 */
    }
    .accept{
      background: #fafafa;
      color: #29AAE3;
    }
    .upload{
      background: #00db76;
      color: white;
    }
    .item:hover{
      box-shadow: 0 0 10px #29AAE3; /* 鼠标悬停时的阴影效果 */
    }
  }
}
</style>