<template>

</template>

<script>
    import * as u from '@/util/util'

    export default {
        name: "tableMixins",
        data() {
            return {
                //钮权限检查显示方法
                buttonPermissionsCheck: u.buttonPermissionsCheck,
                // 搜索条件
                searchForm: {},
                // 表格加载标志
                tableLoading: false,
                // 表格数据
                tableData: [],
                // 表单数据
                form: {},
                //当前选中
                obj: {},
                // 分页参数
                page: {
                    total: 0,  // 总记录数
                    currentPage: 1,  // 当前页数
                    pageSize: 20,  // 每页显示多少条
                    sortdatafield: "create_date",  // 排序字段名
                    sortorder: "desc"  // 排序方式 asc/desc
                },
                //表格ref
                ref:"crud",
                //新增url
                addUrl: "add",
                //删除url
                delUrl: "delete-by-id",
                //修改
                updateUrl: 'update',
                //查询url
                listUrl: 'findPage',
            }
        },
        //开启路由缓存解决表格错位问题
        activated () {
            this.$nextTick(() => {
                this.$refs.crud.doLayout()
            })
        },
        methods: {
            /**
             * 移除avue自动生成的$开头的参数
             * @param obj
             */
            del$(obj) {
                let row = u.copyBean(obj);
                for (let key in row) {
                    if (key.indexOf("$") == 0) {
                        delete row[key];
                    }
                }
                return row;
            },


            /**
             * 删除数据中不不在option的参数
             * @param data
             * @param type：add/update
             */
            delNotField(data, type) {
                let row = u.copyBean(data);
                for (let key in row) {
                    //删除字段不存在的
                    if (this.tableOption.column.filter(x => x.prop == key).length == 0) {
                        //删除字段
                        delete row[key];
                    }
                }
                //删除不显示的字段
                this.tableOption.column.forEach(x => {
                    //删除新增不显示的字段
                    if (x.addDisplay == false && type == 'add' && x.label != 'ID') {
                        delete row[x.prop];
                    }
                    if (x.editDisplay == false && type == 'update' && x.label != 'ID') {
                        delete row[x.prop];
                    }
                })
                return row;
            },

            /**
             * 获取id字段
             **/
            getIdField() {
                let field = "id";
                this.tableOption.column.filter(x => {
                    if (x.label == 'ID') {
                        field = x.prop;
                    }
                })
                return field;
            },

            /**
             * 新增保存
             * @param row 行数据
             * @param done 完成回调
             * @param loading 加载回调
             */
            handleSave: function (row, done, loading) {
                this.$HttpService.post("/" + this.url + "/" + this.addUrl, this.delNotField(this.del$(row), "add")).then(res => {
                    this.$message.success('新增成功');
                    this.getList();
                    done();
                }).catch(() => {
                    loading();
                })

            },


            /**
             * 修改保存
             * @param row 行数据
             * @param index 行索引
             * @param done 完成回调
             * @param loading 加载回调
             */
            handleUpdate: function (row, index, done, loading) {
                this.$util.put("/" + this.url + "/" + this.updateUrl, this.delNotField(row, "update")).then(res => {
                    this.$message.success('修改成功');
                    this.getList();
                    done();
                }).catch(() => {
                    loading();
                })
            },

            /**
             * 删除
             * @param row 行数据
             * @param index 行索引
             */
            rowDel: function (row) {
                this.$confirm('是否确认删除？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$HttpService.del("/" + this.url + "/" + this.delUrl + "/" + row[this.getIdField()]).then(res => {
                        this.$message.success('删除成功');
                        this.getList();
                    })
                })
            },

            /**
             * 获取列表数据
             * @param page 分页参数
             * @param params 查询条件
             */
            getList(page, params) {
                this.tableLoading = true;
                let query = Object.assign({}, this.searchForm, this.page);
                this.$HttpService.get("/" + this.url + "/" + this.listUrl, query).then(res => {
                    this.tableData = res.records;
                    this.page.total = res.total;
                    this.tableLoading = false;
                }).catch(() => {
                    this.tableLoading = false;
                })
            },

            /**
             * 分页大小改变
             * @param pageSize 分页大小
             */
            sizeChange(pageSize) {
                this.page.pageSize = pageSize;
                this.page.currentPage = 1;
                this.getList();
            },
            /**
             * 当前页改变
             * @param current 当前页
             */
            currentChange(current) {
                this.page.currentPage = current;
                this.getList();
            },
            /**
             * 搜索
             * @param form 查询条件
             * @param done 完成回调
             */
            searchChange(form, done) {
                this.searchForm = form;
                this.page.currentPage = 1;
                this.getList();
                done();
            },
            /**
             * 刷新
             */
            refreshChange() {
                this.getList();
            },
        }
    }
</script>

<style scoped>

</style>
