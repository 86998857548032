<template>
  <div class="box">
    <avue-crud :ref="ref"
               v-model="form"
               :page="page"
               :data="tableData"
               :table-loading="tableLoading"
               :option="tableOption"
               @on-load="getList"
               @search-change="searchChange"
               @refresh-change="refreshChange"
               @size-change="sizeChange"
               @current-change="currentChange"
               @row-update="handleUpdate"
               @row-save="handleSave"
               @row-del="rowDel">
      <template slot="menu" slot-scope="scope">
        <el-button  v-if="buttonPermissionsCheck('BASE_SCHEDULED_TASK_START') && scope.row.state=='02'"  :size="scope.size" icon="el-icon-video-play" @click="start(scope.row.id)" type="text">开启</el-button>
        <el-button  v-if="buttonPermissionsCheck('BASE_SCHEDULED_TASK_STOP') && scope.row.state=='01'"  :size="scope.size" icon="el-icon-video-pause" @click="stop(scope.row.id)" type="text">暂停</el-button>

      </template>
    </avue-crud>
  </div>
</template>

<script>
    import {tableOption} from '@/js/base/scheduled_task/list.js'
    import tableMixins from "@/mixins/tableMixins.vue"

    export default {
        name: "base_scheduled_task_list",
        mixins: [tableMixins],
        data() {
            return {
                //访问接口前缀
                url: "base-scheduled-task",
                // 表格参数
                tableOption: tableOption,
                //弹框显示判断
                dialogVisible: false,

            }
        },
        created() {
        },
        methods: {
          //启动任务
          start(id){
            this.$confirm('是否确认启动任务?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(res=>{
              this.$HttpService.post(`${this.url}/start/${id}`).then(res=>{
                this.$message.success('操作成功');
                this.getList();
              })
            })
          },
          //暂停任务
          stop(id){
            this.$confirm('是否确认暂停任务?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(res=>{
              this.$HttpService.post(`${this.url}/stop/${id}`).then(res=>{
                this.$message.success('操作成功');
                this.getList();
              })
            })
          },
        }
    }
</script>

<style scoped>

</style>
