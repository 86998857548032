<template>
  <div class="egisterElement">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
      <el-form-item label="邮箱：" prop="username">
        <el-input onkeydown="if(event.keyCode==13) {document.getElementById('btn').click();return false;}"
            v-model="ruleForm.username"></el-input>
      </el-form-item>
      <el-form-item label="密码：" prop="password">
        <el-input type="password"
                  onkeydown="if(event.keyCode==13) {document.getElementById('btn').click();return false;}"
                  v-model="ruleForm.password"/>
      </el-form-item>

      <el-form-item label="验证码：" prop="code">
        <div style="display: flex">
          <el-input type="text"
                    onkeydown="if(event.keyCode==13) {document.getElementById('btn').click();return false;}"
                    v-model="ruleForm.code"></el-input>
          <div class="login-code">
            <el-button @click="getCode" :disabled="getBtn!='获取'">{{getBtn}}</el-button>
          </div>
        </div>
      </el-form-item>
    </el-form>
    <div style="width: 100%;text-align: center">
      <el-button type="primary" id="btn" @click="register()">注册</el-button>
    </div>
  </div>
</template>

<script>
import * as u from "@/util/util";

export default {
  name: "loginElement",
  data(){
    return{
      ruleForm: {
        username: '',
        password: '',
        code: '',
      },
      rules: {
        username: [
          {required: true, message: '请输入帐号', trigger: 'blur'},
        ],
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'},
        ],
        code: [
          {required: true, message: '请输入验证码', trigger: 'blur'},
        ],
      },
      //获取按钮显示内容
      getBtn:"获取",
    }
  },
  created(){

  },
  methods: {
    register() {
      this.$confirm('是否确认注册？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(res=>{
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            this.$HttpService.post("/sys-login/register", this.ruleForm).then(()=>{
              this.ruleForm = {
                username: '',
                password: '',
                code: '',
              }
              this.$message.success("注册成功！");
            })
          }
        });
      })
    },

    //获取验证码
    getCode() {
      if (this.ruleForm.username === "" || this.ruleForm.username == null) {
        this.$message.error("请输入邮箱地址!!")
        return;
      }
      this.$HttpService.get("/sys-login/getEmailCode/" + this.ruleForm.username, {}).then(res=>{
        this.$message.success("获取成功，请通过邮箱查看验证码！")
        this.getBtn = 60;
        //开始计时
        var int = self.setInterval(() => {
          this.getBtn--;
          if (this.getBtn === 55) {
            this.getBtn = "获取";
            int = window.clearInterval(int);
          }
        }, 1000);
      })
    },

  }
}
</script>


<style scoped>

</style>