<template>
    <div class="box">
        <avue-crud :ref="ref"
                   v-model="form"
                   :page="page"
                   :data="tableData"
                   :table-loading="tableLoading"
                   :option="tableOption"
                   @on-load="getList"
                   @search-change="searchChange"
                   @refresh-change="refreshChange"
                   @size-change="sizeChange"
                   @current-change="currentChange"
                   @row-update="handleUpdate"
                   @row-save="handleSave"
                   @row-del="rowDel">
        </avue-crud>
    </div>
</template>

<script>
    import {tableOption} from '@/js/sys/oper_log/list.js'
    import tableMixins from "@/mixins/tableMixins.vue"

    export default {
        name: "oper_log_list",
        mixins: [tableMixins],
        data() {
            return {
                //访问接口前缀
                url: "sys-oper-log",
                // 表格参数
                tableOption: tableOption,
                //弹框显示判断
                dialogVisible: false,
                page: {
                    total: 0,  // 总记录数
                    currentPage: 1,  // 当前页数
                    pageSize: 20,  // 每页显示多少条
                    sortdatafield: "oper_time",  // 排序字段名
                    sortorder: "desc"  // 排序方式 asc/desc
                },
            }
        },
        created() {},
        methods: {}
    }
</script>

<style scoped>

</style>
