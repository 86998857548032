<template>
  <div>
    <div v-for="item in data" :key="item.id">
      <el-submenu :index="item.id" v-if="item.children!=null && item.children.length!=0">
        <template slot="title">
          <i :class="item.icon"></i>
          <span slot="title">{{item.name}}</span>
        </template>
        <tree_menu :data="item.children"></tree_menu>
      </el-submenu>
      <el-menu-item v-else :index="item.id">
        <i :class="item.icon"></i>
        <span slot="title">{{item.name}}</span>
      </el-menu-item>
    </div>
  </div>
</template>

<script>
  export default {
    name: "tree_menu",
    components: {
      'tree_menu': () => import('@/components/menu/tree_menu.vue')
    },
    props: {
      data: Array,
    },
    methods: {}
  }
</script>

<style scoped lang="scss">

  /*隐藏文字*/
  .el-menu--collapse  .el-submenu__title span{
    display: none;
  }
  /*隐藏 > */
  .el-menu--collapse  .el-submenu__title .el-submenu__icon-arrow{
    display: none;
  }
</style>
