<template>
  <div class="accept">
    <span>接收</span>
  </div>
</template>
<script>
export default {
  name: "accept"
}
</script>

<style scoped lang="scss">
    .accept{

    }
</style>