<template>
  <div class="box">
    <avue-crud :ref="ref"
               v-model="form"
               :page="page"
               :data="tableData"
               :table-loading="tableLoading"
               :option="tableOption"
               @on-load="getList"
               @search-change="searchChange"
               @refresh-change="refreshChange"
               @size-change="sizeChange"
               @current-change="currentChange"
               @row-update="handleUpdate"
               @row-save="handleSave"
               @row-del="rowDel">
      <template slot="menu" slot-scope="scope">
        <el-button :size="scope.size" icon="el-icon-s-tools" @click="openDia(scope.row)" type="text">字典项</el-button>
      </template>
    </avue-crud>


    <el-dialog
      title="设置字典项"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="50%">
      <el-button v-if="buttonPermissionsCheck('SYS_DICT_ITEM_ADD')" type="primary" size="small" icon="el-icon-plus"
                 @click="openItemDia()">新增
      </el-button>
      <el-table
        :data="itemData"
        height="500px"
        style="width: 100%">
        <el-table-column
          prop="label"
          label="字典名"
          width="180">
        </el-table-column>
        <el-table-column
          prop="value"
          label="字典值"
          width="180">
        </el-table-column>
        <el-table-column
          prop="desc"
          label="描述">
        </el-table-column>
        <el-table-column
          fixed="right"
          label="操作"
          width="100">
          <template slot-scope="scope">
            <el-button v-if="buttonPermissionsCheck('SYS_DICT_ITEM_UPDATE')" type="text" size="small"
                       @click="openItemDia(scope.row)">编辑
            </el-button>
            <el-button v-if="buttonPermissionsCheck('SYS_DICT_ITEM_DEL')" type="text" size="small"
                       @click="delItem(scope.row.id)">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>

    <el-dialog
      title="编辑字典项"
      :visible.sync="dialogVisible2"
      :close-on-click-modal="false"
      width="50%">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="字典名：" prop="label">
          <el-input v-model="ruleForm.label"></el-input>
        </el-form-item>
        <el-form-item label="字典值：" prop="value">
          <el-input v-model="ruleForm.value"></el-input>
        </el-form-item>
        <el-form-item label="排序：" prop="sort">
          <el-input-number v-model="ruleForm.sort" :precision="0"></el-input-number>
        </el-form-item>
        <el-form-item label="描述：">
          <el-input v-model="ruleForm.desc"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" :loading="itemLoading" @click="saveItem()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
    import {tableOption} from '@/js/sys/dict/list.js'
    import tableMixins from "@/mixins/tableMixins.vue"

    export default {
        name: "sys_dict_list",
        mixins: [tableMixins],
        data() {
            return {
                //avue表格参数
                tableOption: tableOption,
                //访问接口前缀
                url: "sys-dict",
                //弹框显示判断
                dialogVisible: false,
                //编辑字典项
                dialogVisible2: false,
                //字典项集合
                itemData: [],
                //提交变蛋
                ruleForm: {
                    label: '',
                    val: '',
                    desc: '',
                    sort: '',
                },
                rules: {
                    label: [
                        {required: true, message: '请输入', trigger: 'blur'},
                    ],
                    val: [
                        {required: true, message: '请输入', trigger: 'blur'},
                    ],
                    sort: [
                        {required: true, message: '请输入', trigger: 'blur'},
                    ]
                },
                //字典项弹框加载
                itemLoading:false,
            }
        },
        created() {

        },
        methods: {
            //删除字典项
            delItem(id) {
                this.$confirm('是否确认删除？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$HttpService.del("/sys-dict-item/delete-by-id/" + id).then(res => {
                        this.$message.success('删除成功');
                        this.getItem();
                    })
                })
            },

            saveItem() {
                this.itemLoading=true;
                this.$refs.ruleForm.validate((valid) => {
                    if (valid) {
                        if (this.ruleForm.id == null || this.ruleForm.id == "") {
                            this.$HttpService.post("/sys-dict-item/add", Object.assign(this.ruleForm, {
                                dictId: this.obj.id
                            })).then(res => {
                                this.getItem();
                                this.dialogVisible2 = false;
                                this.itemLoading=false;
                                this.$message.success("操作成功！");
                            }).catch(res=>{
                                this.itemLoading=false;
                            })
                        } else {
                            this.$util.put("/sys-dict-item/update", this.ruleForm).then(res => {
                                this.getItem();
                                this.dialogVisible2 = false;
                                this.itemLoading=false;
                                this.$message.success("操作成功！");
                            }).catch(()=>{
                                this.itemLoading=false;
                            })
                        }


                    }
                });
            },

            //打开弹框
            openDia(row) {
                this.obj = row;
                this.getItem()
                this.dialogVisible = true;
            },

            //打开字典项框
            openItemDia() {
                this.ruleForm = this.$options.data().ruleForm;
                this.dialogVisible2 = true;
            },

            //打开字典项框
            openItemDia(row) {
                if (row == null) {
                    this.ruleForm = this.$options.data().ruleForm;
                } else {
                    this.ruleForm = row;
                }

                this.dialogVisible2 = true;
            },
            //查询出当前弹窗的字典项
            getItem() {
                this.$HttpService.get("/sys-dict-item/findList", {
                    "dictId": this.obj.id,
                }).then(res => {
                    this.itemData = res;
                })
            }
        }

    }
</script>

<style scoped>

</style>
