<template>
  <div class="box">
    <el-row>
      <el-col :span="24">
        <div class="block">
          <span class="welcome" style="color: red;margin-right: 5px">{{userInfo.name}}</span> 您好！欢迎使用HT文件管理系统!
          当前时间：{{new Date() | fmtDate}}
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="4" class="highlight">
        <div class="block">
          <span>用户数</span>
          <p class="label">300</p>
        </div>
      </el-col>
      <el-col :span="4">
        <div class="block">
          <span>会员数</span>
          <p class="label">59</p>
        </div>
      </el-col>
      <el-col :span="4">
        <div class="block">
          <span>资源数</span>
          <p class="label">120</p>
        </div>
      </el-col>
      <el-col :span="4">
        <div class="block">
          <span>文章数</span>
          <p class="label">300</p>
        </div>
      </el-col>
      <el-col :span="4">
        <div class="block">
          <span>分享量</span>
          <p class="label">200</p>
        </div>
      </el-col>
      <el-col :span="4">
        <div class="block">
          <span>下载量</span>
          <p class="label">150</p>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-card class="box-card">
          <span>系统信息</span>
          <el-divider></el-divider>
          <el-table
            :data="sysInfo"
            :show-header="false"
            border
            style="width: 100%">
            <el-table-column
              prop="label">
            </el-table-column>
            <el-table-column
              prop="value">
            </el-table-column>
          </el-table>
        </el-card>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-card class="box-card">
          <my_chart style="height: 320px" :option="option"></my_chart>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
    import * as c from '@/util/config'
    import * as u from '@/util/util'
    import {mapActions} from 'vuex'

    export default {
        name: "sy",
        components: {
            'my_chart': () => import('@/components/myChart/index.vue'),
        },
        data() {
            return {
                //用户信息
                userInfo: u.getSession(c.sessionKey.USER_INFO),
                //系统信息
                sysInfo: [
                    {label: '系统版本', value: "2.0v"},
                    {label: '操作系统', value: "windows"},
                    {label: '数据库', value: "mysql5.7"},
                    {label: '运行环境', value: "jdk8/maven/nodeV16.14.1/redis"},
                ],
                option: {
                    title: {
                        text: '系统使用走势图'
                    },
                    tooltip: {
                        trigger: 'axis'
                    },
                    legend: {
                        data: ['新增用户', '新增资源', '下载量']
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    toolbox: {
                        feature: {
                            saveAsImage: {}
                        }
                    },
                    xAxis: {
                        type: 'category',
                        boundaryGap: false,
                        data: ['1月', '2月', '3月', '4月', '5月', '6月']
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: [
                        {
                            name: '新增用户',
                            type: 'line',
                            stack: '总量',
                            data: [120, 132, 101, 134, 90, 230, 210]
                        },
                        {
                            name: '新增资源',
                            type: 'line',
                            stack: '总量',
                            data: [220, 182, 191, 234, 290, 330, 310]
                        },
                        {
                            name: '下载量',
                            type: 'line',
                            stack: '总量',
                            data: [150, 232, 201, 154, 190, 330, 410]
                        }
                    ]
                }
            }
        },

        methods: {
            ...mapActions(["openWebSocket", "closeWebSocket"]),
        },
        beforeDestroy() {
            //关闭socket连接
            this.closeWebSocket();
        }
    }
</script>

<style scoped lang="scss">
  .box {
    max-width: 100%;
    min-height: 100%;

    .block {
      margin-bottom: 20px;
      padding: 15px 0;
      background: #f2f2f2;
      color: #140000;

      span {
        margin-left: 20px;
      }

      .label {
        margin-left: 20px;
        color: #009688;
        font-size: 25px;
        margin-top: 10px;
        font-weight: bold;
      }
    }

    .block:hover {
      background: #dcdcdc;
      cursor: pointer;
    }
  }


</style>
