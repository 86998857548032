<template>
  <div class="box">
    <avue-crud :ref="ref"
               v-model="form"
               :page="page"
               :before-open="beforeOpen"
               :data="tableData"
               :table-loading="tableLoading"
               :option="tableOption"
               @on-load="getList"
               @search-change="searchChange"
               @refresh-change="refreshChange"
               @size-change="sizeChange"
               @current-change="currentChange"
               @row-update="handleUpdate"
               @row-save="handleSave"
               @row-del="rowDel">
      <template slot-scope="scope" slot="menuLeft">
        <el-button v-if="buttonPermissionsCheck('SYS_MENU_ADD')" type="primary" icon="el-icon-plus" :size="scope.size" @click="handleAdd(null)">新增</el-button>
      </template>
      <template slot="menu" slot-scope="scope">
        <el-button v-if="buttonPermissionsCheck('SYS_MENU_ADD')" :size="scope.size" @click="handleAdd(scope.row)" type="text">
          添加
        </el-button>
      </template>
    </avue-crud>
  </div>
</template>

<script>
  import {tableOption} from '@/js/sys/menu/list.js'
  import {btn} from '@/js/sys/menu/btn.js'
  import {menu} from '@/js/sys/menu/menu.js'
  import {page} from '@/js/sys/menu/page.js'
  import tableMixins from "@/mixins/tableMixins.vue"
  export default {
    name: "sys_menu_list",
    mixins:[tableMixins],
    watch:{
      "form.type":{
        deep:true,
        handler(val){
          if(val=='0'){
            //菜单
            this.tableOption=menu;
          }else if(val=='1'){
            //页面
            this.tableOption=page;
          }else if(val=='2') {
            //按钮
            this.tableOption = btn;
          }
        }
      },
    },
    data() {
      return {
        //访问接口前缀
        url:"sys-menu",
        // 表格参数
        tableOption: tableOption,
        //弹框显示判断
        dialogVisible:false,

      }
    },
    created(){

    },
    methods: {

      /**
       * 自定义新增
       */
      handleAdd(row) {
        this.obj=row;
        this.$refs.crud.rowAdd();
      },


      //弹框打开前的回调
      beforeOpen(done,type) {
        this.tableLoading=true;
        this.tableOption.column[1].dicData=this.tableData;
        setTimeout(res=>{
          if(type=='add' &&this.obj!=null){
            this.form.pid=this.obj.id;
          }
        },50)
        done();
        this.tableLoading=false;

      },

      /**
       * 新增保存
       * @param row 行数据
       * @param done 完成回调
       * @param loading 加载回调
       */
      handleSave: function (row, done, loading) {
        if(row.pid=='' || row.pid==null){
          row.pid=0;
        }
        if(row.type!="1"){
          //不是页面
          row.path="";
          row.cache="";
        }

        this.$HttpService.post("/sys-menu/add", row).then(res=>{
          this.$message.success('新增成功');
          this.getList();
          done();
        }).catch(()=>{
            loading();
        })
      },


      /**
       * 修改保存
       * @param row 行数据
       * @param index 行索引
       * @param done 完成回调
       * @param loading 加载回调
       */
      handleUpdate: function (row, index, done, loading) {
        if(row.pid=='' || row.pid==null){
          row.pid=0;
        }
        if(row.type!="1"){
          //不是页面
          row.path="";
          row.cache="";
        }
        this.$util.put("/" + this.url + "/" + this.updateUrl,row).then(res => {
          this.$message.success('修改成功');
          this.getList();
          done();
        }).catch(() => {
          loading();
        })
      },


      /**
       * 获取列表数据
       * @param page 分页参数
       * @param params 查询条件
       */
      getList(page, params) {
        this.tableLoading = true;
        let query = Object.assign({}, this.searchForm, this.page);
        this.$HttpService.get("/sys-menu/findMenuTree", query).then(res=>{
          this.tableData = res;
          this.tableLoading = false;
        })
      },
    }
  }
</script>

<style scoped>

</style>
