<template>
    <div class="admin_box" id="admin_box">
        <div class="b_top">
            <!--顶部导航-->
            <top_menu></top_menu>
        </div>
        <div class="b_bot">
            <div class="menu">
                <!--右边菜单-->
                <el-menu class="my_el_menu"
                         :collapse="menuState"
                         :default-active="thisMenu"
                         :active="thisMenu"
                         @select="menuClick"
                         background-color="#334157"
                         text-color="#fff"
                         active-text-color="#ffd04b">
                    <tree_menu :data="menuTree"></tree_menu>
                </el-menu>
            </div>
            <div class="view_main scroll-bar" id="view_main">
                <!--页面主体-->
                <el-tabs v-model="thisMenu" type="card" @contextmenu.prevent.native="openContextMenu" id="menuTab"
                         @tab-remove="removeTab">
                    <el-tab-pane
                            v-for="(item, index) in openMenu"
                            :closable="item.id=='-1'?false:true"
                            :key="item.id"
                            :label="item.name"
                            :name="item.id">
                    </el-tab-pane>
                </el-tabs>
                <div class="view" id="view">
                    <keep-alive>
                        <router-view v-if="$route.meta.keepAlive" class="router"></router-view>
                    </keep-alive>
                    <router-view v-if="!$route.meta.keepAlive" class="router"></router-view>
                </div>
            </div>
        </div>
        <topMenu ref="rightTab" v-bind="rightTab"></topMenu>
    </div>
</template>

<script>
    import * as c from '@/util/config'
    import * as u from '@/util/util'

    var elementResizeDetectorMaker = require("element-resize-detector")
    export default {
        name: "admin",
        components: {
            'tree_menu': () => import('@/components/menu/tree_menu.vue'),
            'top_menu': () => import('@/components/topMenu/index.vue'),
            'topMenu': () => import('@/components/rightTab/topMenu.vue'),
        },
        watch: {
            //监听菜单状态变化
            menuState: function (val) {
                //存入session
                u.setSession(c.sessionKey.MENU_STATE, val);
            },
            $route: {
                handler: function (val, oldVal) {
                    console.log(val);
                    //判断切换的是否是首页
                    if (c.HOME_PAGE.path === val.path) {
                        this.menuClick(c.HOME_PAGE.id);
                    } else {
                        //获取当前要切换路由的菜单对象
                        let menu = this.menuList.filter(x => x.path === val.path)[0];
                        this.menuClick(menu.id);
                    }
                },
                // 深度观察监听
                deep: true
            },
            //监听菜单切换
            thisMenu: function (val) {
                if (this.$route.path !== this.openMenu.filter(x => x.id === val)[0].path) { //切换到不同路由才进行路由切换
                    this.openUrl = this.openMenu.filter(x => x.id === val)[0].path;
                    this.$router.push(this.openUrl)
                }
            }
        },
        computed: {
            //打开过的菜单
            openMenu() {
                return this.$store.state.menuTab.openMenu
            },
            thisMenu: {
                get() {
                    return this.$store.state.menuTab.thisMenu;
                },
                set(value) {
                    this.$store.commit("setThisMenu", value);
                }
            },

        },
        data() {
            return {
                //菜单状态
                menuState: u.getSession(c.sessionKey.MENU_STATE) ? u.getSession(c.sessionKey.MENU_STATE) : false,
                //打开的路径
                openUrl: c.HOME_PAGE.path,
                //用户信息
                userInfo: u.getSession(c.sessionKey.USER_INFO),
                //菜单集合
                menuTree: u.getSession(c.sessionKey.SYS_MENU_TREE),
                menuList: [],
                //菜单树参数对应配置
                defaultProps: {
                    children: 'children',
                    label: 'name',
                },
                //监听右键tab弹窗状态
                rightTab: {
                    show: false,
                    top: 0,
                    left: 0,
                    menu:null,
                }
            }
        },
        created() {
            this.traverseMenuTree(this.menuTree);
        },
        mounted() {
            this.$nextTick(() => {
                this.initDom();
            })
            elementResizeDetectorMaker().listenTo(document.getElementById("admin_box"), element => {
                // 重置图表
                this.$nextTick(() => {
                    this.initDom();
                })
            })
        },
        methods: {
            openContextMenu: function (e) {
                e.preventDefault(); //防止默认菜单弹出
                let obj = e.srcElement ? e.srcElement : e.target;
                if (obj.id) {
                    //获取菜单id
                    let menuId = obj.id.split("tab-")[1];
                    if(menuId===this.thisMenu){
                        //找到当前全中的标签
                        let menu = this.openMenu.filter(x => x.id === menuId)[0];
                        this.rightTab.left = e.clientX;
                        this.rightTab.top = e.clientY + 10;
                        this.$refs.rightTab.openDia(menu);
                    }

                }
            },

            //编辑菜单树
            traverseMenuTree(tree) {
                tree.forEach(x => {
                    if (x.children != null && x.children.length > 0) {
                        this.traverseMenuTree(x.children);
                    }
                    this.menuList.push(x);
                })
            },

            //菜单点击事件
            menuClick(key) {
                this.$store.commit("setThisMenu", key);
                //判断当前节点是否已经加入openMenu;
                if (this.openMenu.filter(x => x.id === key).length === 0) {
                    this.$store.commit("addOpenMenu", this.traverseTreeMenu(this.menuTree, key));

                }
            },

            //遍历找到菜单树找到当前指定菜单
            traverseTreeMenu(list, id) {
                let row;
                for (let i = 0; i < list.length; i++) {
                    if (list[i].children != null && list[i].children.length !== 0) {
                        row = this.traverseTreeMenu(list[i].children, id)
                        if (row != null) {
                            return row;
                        }
                    }
                    if (id === list[i].id) {
                        row = list[i];
                    }
                }
                return row;
            },

            //下拉菜单触发时间
            handleCommand(command) {
                switch (command) {
                    case 'userInfo':
                        break
                    case 'logout':
                        this.$confirm('是否确认退出登录?', '提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(() => {
                            u.post("/sys-login/logout").then(res => {
                                u.clearSession();
                                this.$router.push('/login')
                            })
                        })
                        break
                }
            },
            //菜单隐藏显示
            unfold(val) {
                this.menuState = val;
            },

            /**
             * 删除一个tab
             * @param targetName
             */
            removeTab(delKey) {
                //找到需要删除的节点
                let delIndex = this.openMenu.findIndex(x => x.id === delKey);
                this.$store.commit("delOpenMenu", delIndex);
                //判断删除的id是否等于当前选择的id
                if (delKey === this.thisMenu) {
                    if (delIndex >= this.openMenu.length) {
                        this.$store.commit("setThisMenu", this.openMenu[this.openMenu.length - 1].id);
                    } else {
                        this.$store.commit("setThisMenu", this.openMenu[delIndex].id);
                    }
                }
            },

            /**
             * 初始化domjie
             */
            initDom() {
                //获取view_main的高度
                let viewMain = document.getElementById("view_main").offsetHeight;
                let menuTab = document.getElementById("menuTab").offsetHeight;
                document.getElementById("view").style.height = viewMain - menuTab - 50 + "px";
            }

        }
    }
</script>

<style scoped lang="scss">
    .admin_box {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;


        .b_top {
            width: 100%;
            display: flex;
            color: white;
        }

        .b_bot {
            flex: 1;
            width: 100%;
            display: flex;

            .menu {
                height: 100%;
                background-color: #334157;

                ::v-deep .my_el_menu:not(.el-menu--collapse) {
                    width: 200px;
                    height: 100% !important;
                }

                .my_el_menu {
                    border: 0px !important;
                }
            }

            .view_main {
                height: 100%;
                flex: 1;
                display: flex;
                flex-direction: column;

                .view {

                    width: 99%;
                    margin: 0 auto;
                    overflow-y: auto;
                    overflow-x: hidden;

                    .router {
                        width: 99%;
                        height: 100%;
                        margin: 0 auto;
                    }
                }
            }
        }
    }


</style>
