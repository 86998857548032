<template>
  <!--右键tab页组件-->
  <div v-show="show">
    <ul :style="{left:left+'px',top:top+'px'}" class="contextmenu">
      <li>
        <el-button type="text" size="mini" @click="operation('1')">重新加载</el-button>
      </li>
      <li>
        <el-button type="text" size="mini" @click="operation('2')">关闭所有</el-button>
      </li>
      <li>
        <el-button type="text" size="mini" @click="operation('3')">关闭左边</el-button>
      </li>
      <li>
        <el-button type="text" size="mini" @click="operation('4')">关闭右边</el-button>
      </li>
      <li>
        <el-button type="text" size="mini" @click="operation('5')">关闭其他</el-button>
      </li>
    </ul>
  </div>
</template>

<script>
    import {mapActions } from 'vuex'
    export default {
        name: "index",
        props: {
            left: Number,
            top: Number,
            //当前选中的菜单
            menuId:String,
        },
        data() {
            return {
                //显示标识
                show: false,
                //当前选中的菜单
                menu: null,
            }
        },
        mounted() {
            window.onclick = res => {
                this.show = false;
            }
        },

        methods: {
            ...mapActions(["closeAll","closeLeft","closeRight","closeOther"]),


          //操作
          operation(code){
              switch (code) {
                  case "1":   //重新加载
                    this.$router.go(0);
                    break
                  case "2":   //关闭所有
                    this.closeAll();
                    //切换到sy
                    this.$router.push(this.$config.HOME_PAGE.path);
                    break
                  case "3":   //关闭左边
                    this.closeLeft(this.menu.id);
                    break
                  case "4":   //关闭右边
                    this.closeRight(this.menu.id);
                    break
                  case "5":   //关闭其他
                    this.closeOther(this.menu.id);
                    break
              }
            this.show = false;
          },

            /**
             * 打开当前弹框
             * @param menu
             */
            openDia(menu) {
                this.show = true;
                this.menu = menu;
            },
        }

    }
</script>

<style scoped>
  .contextmenu {
    width: 100px;
    margin: 0;
    border: 1px solid #ccc;
    background: #fff;
    z-index: 3000;
    position: absolute;
    list-style-type: none;
    padding: 5px 0;
    border-radius: 4px;
    font-size: 14px;
    color: #333;
    box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.2);
  }

  .contextmenu li {
    margin: 0;
    padding: 0px 22px;

  }

  .contextmenu li:hover {
    background: #f2f2f2;
    cursor: pointer;
  }

  .contextmenu li button {
    color: #2c3e50;
  }
</style>
