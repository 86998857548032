import * as u from '@/util/util'
import * as c from '@/util/config'

export const tableOption = {
  border: true,
  index: true,
  indexLabel: '序号',
  indexWidth: 50,
  stripe: true,
  align: 'center',
  searchMenuSpan: 6,
  labelWidth: 120,
  height: '450px',
  calcHeight: 400,
  dialogWidth: 900,
  menuWidth: 150,
  menuAlign: 'center',
  rowKey:"deptId",
  defaultExpandAll:true,
  addBtn: false,
  editBtn: false,
  viewBtn:true,
  delBtn: u.buttonPermissionsCheck("SYS_OPER_LOG_DEL"),
  column: [
    {
      prop: 'id',
      label: 'ID',
      hide: true,
      viewDisplay: false,
      addDisplay: false,
      editDisplay: false
    },
    {
      prop: 'title',
      label: '日志标题',
      type: 'input',
      minWidth: 50,
      overHidden: true,
      search: true,
    },
    {
      prop: 'msg',
      label: '操作说明  ',
      type: 'input',
      overHidden: true,
      minWidth: 50,
    },
    {
      prop: 'operType',
      label: '操作类型',
      type: 'select',
      minWidth: 50,
      overHidden: true,
      search: true,
      dicUrl:"/common/getItem/oper_log_type",
    },
    {
      prop: 'methodName',
      label: '接口访问方法',
      type: 'input',
      overHidden: true,
      minWidth: 50,
    },
    {
      prop: 'requestMethod',
      label: '接口请求类型  ',
      type: 'input',
      overHidden: true,
      minWidth: 50,
    },
    {
      prop: 'operUrl',
      label: '请求url',
      type: 'input',
      overHidden: true,
      minWidth: 50,
    },
    {
      prop: 'operIp',
      label: '请求主机地址',
      type: 'input',
      overHidden: true,
      minWidth: 50,
    },
    {
      prop: 'states',
      label: '操作状态  ',
      type: 'select',
      overHidden: true,
      minWidth: 50,
      dicUrl:"/common/getItem/oper_log_states"
    },
    {
      prop: 'operTime',
      label: '操作时间  ',
      type: 'input',
      overHidden: true,
      minWidth: 50,
    },
    {
      prop: 'userName',
      label: '操作用户',
      type: 'input',
      overHidden: true,
      minWidth: 50,
    },
    {
      prop: 'param',
      label: '请求参数  ',
      type: 'textarea',
      hide: true,
      span:24,
      overHidden: true,
      minWidth: 50,
    },
    {
      prop: 'errMsg',
      label: '错误信息',
      type: 'textarea',
      hide: true,
      span:24,
      overHidden: true,
      minWidth: 50,
    },
  ]
}

