<template>
  <div class="loginElement">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
      <el-form-item label="帐号：" prop="username">
        <el-input
            onkeydown="if(event.keyCode==13) {document.getElementById('btn').click();return false;}"
            v-model="ruleForm.username"></el-input>
      </el-form-item>
      <el-form-item label="密码：" prop="password">
        <el-input type="password"
                  onkeydown="if(event.keyCode==13) {document.getElementById('btn').click();return false;}"
                  v-model="ruleForm.password"/>
      </el-form-item>
      <el-form-item label="验证码：" prop="code">
        <div style="display: flex">
          <el-input type="text"
                    onkeydown="if(event.keyCode==13) {document.getElementById('btn').click();return false;}"
                    v-model="ruleForm.code"></el-input>
          <div class="login-code">
            <img :src="codeUrl"
                 @click="getCode">
          </div>
        </div>
      </el-form-item>
    </el-form>
    <div style="width: 100%;text-align: center">
      <el-button type="primary" id="btn" @click="login()">登录</el-button>
    </div>
  </div>
</template>

<script>
import * as u from "@/util/util";

export default {
  name: "loginElement",
  data(){
    return{
      //登录表单
      ruleForm: {
        username: 'admin',
        password: '123456',
        code: '',
        randomStr: u.uuid(),
      },
      //登录校验
      rules: {
        username: [
          {required: true, message: '请输入帐号', trigger: 'blur'},
        ],
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'},
        ],
        code: [
          {required: true, message: '请输入验证码', trigger: 'blur'},
        ],
      },
      //验证码图片url
      codeUrl: "",
    }
  },
  created(){
    this.getCode();
  },
  methods: {
    //获取验证码
    getCode() {
      this.$HttpService.get("/sys-login/getCode", {
        randomStr: this.ruleForm.randomStr,
      }).then(res => {
        this.codeUrl = res;
        this.randomStr = u.uuid();
      })
    },
    //登录
    login() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.$HttpService.post("/sys-login/login",this.ruleForm,).then(res => {
            //设置存储用户基本信息
            this.$util.setLoginInfo(res);
            //跳转路由
            setTimeout(() => {
              this.$router.push({path: "/"})
            }, 200)
          }).catch(() => {
            this.getCode();
          })
        }
      });
    }
  }
}
</script>


<style scoped>

</style>