import * as u from '@/util/util'
import * as c from '@/util/config'

export const tableOption = {
  border: true,
  index: true,
  indexLabel: '序号',
  indexWidth: 50,
  stripe: true,
  align: 'center',
  searchMenuSpan: 6,
  labelWidth: 120,
  height: '450px',
  calcHeight: 400,
  dialogWidth: 900,
  menuWidth: 200,
  menuAlign: 'center',
  rowKey:"deptId",
  defaultExpandAll:true,
  addBtn:false,
  editBtn: u.buttonPermissionsCheck("SYS_DEPT_UPDATE"),
  delBtn: u.buttonPermissionsCheck("SYS_DEPT_DEL"),
  column: [
    {
      prop: 'deptId',
      label: 'ID',
      hide: true,
      addDisplay: false,
      editDisplay: false
    },
    {
      prop: 'parentId',
      label: '上级部门',
      type: 'tree',
      hide: true,
      overHidden: true,
      maxlength: 200,
      minWidth: 50,
      defaultExpandAll:true,
      dicUrl:"/sys-dept/findTreeAuth",
      props: {
        label: "deptName",
        value: "deptId",
      },
    },
    {
      prop: 'deptName',
      label: '部门名称',
      type: 'input',
      overHidden: true,
      maxlength: 200,
      minWidth: 50,
      rules: [{
        required: true,
        message: '请输入',
        trigger: 'blur'
      }]
    },
    {
      prop: 'deptCode',
      label: '部门编码',
      type: 'input',
      overHidden: true,
      maxlength: 200,
      minWidth: 50,
      rules: [{
        required: true,
        message: '请输入',
        trigger: 'blur'
      }]
    },
    {
      prop: 'sort',
      label: '排序',
      type: 'number',
      overHidden: true,
      maxlength: 200,
      precision:0,
    },
  ]
}

