import * as u from '@/util/util'
import * as c from '@/util/config'

export const tableOption = {
  border: true,
  index: true,
  indexLabel: '序号',
  indexWidth: 50,
  stripe: true,
  align: 'center',
  searchMenuSpan: 6,
  labelWidth: 120,
  height: '450px',
  calcHeight: 400,
  dialogWidth: 900,
  menuWidth: 250,
  menuAlign: 'center',
  rowKey:"deptId",
  defaultExpandAll:true,
  addBtn: u.buttonPermissionsCheck("BASE_SCHEDULED_TASK_ADD"),
  editBtn: u.buttonPermissionsCheck("BASE_SCHEDULED_TASK_UPDATE"),
  delBtn: u.buttonPermissionsCheck("BASE_SCHEDULED_TASK_DEL"),
  column: [
    {
      prop: 'id',
      label: 'ID',
      hide: true,
      addDisplay: false,
      editDisplay: false
    },
    {
      prop: 'taskName',
      label: '任务名称',
      type: 'input',
      overHidden: true,
      maxlength: 200,
      minWidth: 50,
      span:24,
      search: true,
      rules: [{
        required: true,
        message: '请输入',
        trigger: 'blur'
      }]
    },
    {
      prop: 'backTask',
      label: '执行任务',
      type: 'select',
      overHidden: true,
      maxlength: 255,
      minWidth: 50,
      span:24,
      search: true,
      dicUrl:"/common/getItem/back_task",
      rules: [{
        required: true,
        message: '请输入',
        trigger: 'blur'
      }]
    },
    {
      prop: 'corn',
      label: 'corn表达式',
      type: 'input',
      overHidden: true,
      maxlength: 255,
      minWidth: 50,
      span:24,
      rules: [{
        required: true,
        message: '请输入',
        trigger: 'blur'
      }]
    },
    {
      prop: 'state',
      label: '任务状态',
      type: 'select',
      overHidden: true,
      maxlength: 200,
      minWidth: 50,
      span:24,
      addDisplay: false,
      editDisplay: false,
      dicUrl:"/common/getItem/task_state",
    },
    {
      prop: 'note',
      label: '任务描述',
      type: 'textarea',
      overHidden: true,
      maxlength: 500,
      minWidth: 50,
      span:24,
    },
  ]
}

