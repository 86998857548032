<template>
  <div class="myFileBox">
    <div class="top">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item><span @click="toggleBreadcrumb(null)">首页</span>
        </el-breadcrumb-item>
        <el-breadcrumb-item v-for="item in breadcrumbList"><span @click="toggleBreadcrumb(item)">{{ item.name }}</span>
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div v-loading="loading" class="panel" id="panel" @click="panelClick()" @contextmenu.prevent="openContextMenu">
      <el-empty v-if="list==null || list.length===0" description="还未上传过文件！"></el-empty>
      <div class="fileBox" v-else>
        <!--文件展示-->
        <div class="box" v-for="item in list">
          <folderBox v-if="item.type==='folder'" :id="item.id" :info="item.fileFolder"
                     @dblclick.native="dbClickFolder(item)"></folderBox>
          <fileBox v-if="item.type==='file'" :id="item.id" :info="item.fileInfo"></fileBox>
        </div>
      </div>
      <fileTab ref="rightTab"
               :pid="pid"
               v-bind="rightTab"
               @ok="refresh"
               @decompressionOk="decompressionOk"
               @copyOk="copyOk"
               @moveOk="moveOk"
      ></fileTab>

    </div>

  </div>


</template>

<script>
import * as c from '@/util/config'
import * as u from '@/util/util'

export default {
  name: "myFile",
  components: {
    'fileTab': () => import('@/components/file/fileTab.vue'),
    'fileBox': () => import('@/components/file/fileBox.vue'),
    'folderBox': () => import('@/components/file/folderBox.vue'),
  },
  data() {
    return {
      //加载中
      loading: true,
      search: {
        //文件夹名称
        folderName: "",
      },
      //当前文件夹父id
      pid: "0",
      //文件集合
      list: [],
      //监听右键tab弹窗状态
      rightTab: {
        show: false,
        top: 0,
        left: 0,
        menu: null,
      },
      //面包屑集合
      breadcrumbList: [],
    }
  },
  created() {
    //默认查询第一层级
    this.findLoginUserFolderByPId(this.pid);
  },
  methods: {
    //按层级查询文件夹
    findLoginUserFolderByPId(pid) {
      this.loading = true;
      this.$HttpService.get(`/file-folder/findLoginUserFolderByPId/${pid}`, this.search).then(res => {
        this.list = res;
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      })
    },

    //刷新数据
    refresh() {
      this.findLoginUserFolderByPId(this.pid);
    },
    /**
     * 面板点击事件
     */
    panelClick() {
      this.$refs.rightTab.offDia();
    },

    /**
     * 鼠标右键事件
     * @param e
     */
    openContextMenu: function (e) {
      if (this.loading) {
        return;
      }
      e.preventDefault(); //防止默认菜单弹出
      //获取当前元素
      let obj = e.srcElement ? e.srcElement : e.target;
      let info = null;
      if (obj.id) {
        //找到当前数据
        info = this.list.filter(x => x.id == obj.id)[0];
      }
      this.rightTab.left = e.clientX;
      this.rightTab.top = e.clientY;
      this.$refs.rightTab.openDia(info);
    },


    /**
     * 文件夹双击事件
     * @param item
     */
    dbClickFolder(item) {
      this.pid = item.id;
      //插入面包屑
      this.breadcrumbList.push(item);
      this.refresh();
    },


    /**
     * 切换面包屑
     * @param item
     */
    toggleBreadcrumb(item) {
      if (item == null) {
        this.breadcrumbList = [];
        this.pid = 0;
        this.refresh();
        return;
      }
      //移除当前块及其后面的所有面包屑
      //找到当前元素的索引
      let index = this.breadcrumbList.findIndex(x => x.id === item.id);
      if (index !== -1) {
        // 移除当前元素以及后面的所有元素
        this.breadcrumbList.splice(index);
      }
      this.dbClickFolder(item);
    },


    /**
     * 解压完成回调-打开解压后的文件夹
     * @param node：文件解压选择的文件夹
     * @param path：节点路径
     */
    decompressionOk({node, path}) {
      //删除第一个去掉首页
      this.breadcrumbList = path.filter((_, index) => index !== 0);
      this.pid=node.folderId;
      this.refresh();
    },

    /**
     * 复制完成回调-打开复制后的文件夹
     * @param node：文件复制选择的文件夹
     * @param path：节点路径
     */
    copyOk({node, path}) {
      //删除第一个去掉首页
      this.breadcrumbList = path.filter((_, index) => index !== 0);
      this.pid=node.folderId;
      this.refresh();
    },
    /**
     * 移动回调事件
     * @param node：选择的文件夹
     * @param path：节点路径
     */
    moveOk({node, path}) {
      //删除第一个去掉首页
      this.breadcrumbList = path.filter((_, index) => index !== 0);
      this.pid=node.folderId;
      this.refresh();
    },
  }
}
</script>

<style scoped lang="scss">
.myFileBox {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .top {
    ::v-deep .el-breadcrumb {
      .el-breadcrumb__inner {
        cursor: pointer;
      }

      .el-breadcrumb__inner:hover {
        color: #29AAE3;
      }
    }

  }

  .panel {
    width: 100%;
    flex: 1;

    .fileBox {
      display: flex;
      flex-wrap: wrap; /* 允许换行 */
      gap: 20px; /* 设置间距 */
      .box {
        width: 80px;
        height: 80px;
        margin: 5px; /* 上下左右间距 */
        padding: 10px;
      }
    }
  }


}

</style>
