<template>
  <div class="box">
    <avue-crud :ref="ref"
               v-model="form"
               :page="page"
               :data="tableData"
               :table-loading="tableLoading"
               :option="tableOption"
               @on-load="getList"
               @search-change="searchChange"
               @refresh-change="refreshChange"
               @size-change="sizeChange"
               @current-change="currentChange"
               @row-update="handleUpdate"
               @row-save="handleSave"
               @row-del="rowDel">
      <template slot="state" slot-scope="scope">
        <el-switch
          style="display: block"
          @change="change(scope.row)"
          v-model="scope.row.state"
          active-color="#13ce66"
          inactive-color="#ff4949"
          active-text="启用"
          inactive-text="禁用"
          active-value="01"
          inactive-value="02">
        </el-switch>
      </template>
      <template slot="menu" slot-scope="scope">
        <el-button v-if="buttonPermissionsCheck('SYS_USER_SET_ROLE')" :size="scope.size" icon="el-icon-s-tools"
                   @click="setRoleOpen(scope.row)" type="text">设置角色
        </el-button>
        <el-button v-if="buttonPermissionsCheck('SYS_USER_RESET_PASSWORD')" :size="scope.size" icon="el-icon-refresh"
                   @click="resetPassword(scope.row)" type="text">重置密码
        </el-button>
      </template>
    </avue-crud>
    <el-dialog
      title="设置用户角色"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="25%">
      <el-select style="width: 100%" v-model="rolesVal" multiple placeholder="请选择">
        <el-option
          v-for="item in roles"
          :key="item.id"
          :label="item.name"
          :value="item.id">
        </el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="setRoles()">确 定</el-button>
       </span>
    </el-dialog>
  </div>
</template>

<script>
    import {tableOption} from '@/js/sys/user/list.js'
    import tableMixins from "@/mixins/tableMixins.vue"

    export default {
        name: "sys_user_list",
        mixins: [tableMixins],
        data() {
            return {
                //访问接口前缀
                url: "sys-user",
                // 表格参数
                tableOption: tableOption,
                //弹框显示判断
                dialogVisible: false,
                //角色集合
                roles: [],
                //设置的角色集合
                rolesVal: [],
            }
        },
        created() {
            this.init();
        },

        methods: {
            //修改用户状态
            change(row){
                this.$HttpService.post(this.url+"/updateState/"+row.id+"/"+row.state).then(res=>{
                    this.getList();
                }).cache(()=>{
                    this.getList();
                })
            },

            //设置用户角色
            setRoles() {
                this.$HttpService.post("/" + this.url + "/setRoles", {
                    id: this.obj.id,
                    roles: this.rolesVal,
                }).then(res => {
                    this.dialogVisible = false;
                    this.$message.success("操作成功！");
                    this.searchChange();
                })
            },

            //打开设置角色弹框
            setRoleOpen(row) {
              this.obj = row;
              this.rolesVal=row.roleIds?row.roleIds.split(","):[];
              this.dialogVisible = true;
            },

            //重置密码
            resetPassword(row) {
                this.$confirm('是否确认重置密码？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$HttpService.post("/sys-user/resetPassword/" + row.id).then(res => {
                        this.$message.success("操作成功！");
                    })
                })
            },
            //初始化
            init() {
                //获取所有角色
                this.$HttpService.get("/sys-role/findList").then(res => {
                    this.roles = res;
                })
            }
        }
    }
</script>

<style scoped>

</style>
