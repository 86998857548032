<template>
  <div class="send" @click.stop="$refs.agreeDia.openDia()">
    <span >发送</span>
    <agreeDia ref="agreeDia"></agreeDia>
  </div>
</template>
<script>
export default {
  name: "send",
  components: {
    'agreeDia': () => import('@/components/tool/agreeDia.vue'),
  },
  data() {
    return {
    }
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
    .send{

    }
</style>