<template>
    <!--修改密码-->
    <el-dialog
            title="修改密码"
            :close-on-click-modal="false"
            :visible.sync="dialogVisible"
            width="30%">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
            <el-form-item label="旧密码：" prop="oldPassword">
                <el-input maxlength="11" type="password" v-model="ruleForm.oldPassword"></el-input>
            </el-form-item>
            <el-form-item label="新的密码：" prop="password">
                <el-input maxlength="11" type="password" v-model="ruleForm.password"></el-input>
            </el-form-item>
            <el-form-item label="确认密码：" prop="confirmPassword">
                <el-input maxlength="11" type="password" v-model="ruleForm.confirmPassword"></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="submit()">提 交</el-button>
          </span>
    </el-dialog>

</template>

<script>
    export default {
        name: "update_password",
        data() {
            return {
                dialogVisible: false,
                ruleForm: {
                    oldPassword: '',
                    password: '',
                    confirmPassword: '',
                },
                rules: {
                    oldPassword: [
                        {required: true, message: '请输入', trigger: 'blur'},
                    ],
                    password: [
                        {required: true, message: '请输入', trigger: 'blur'},
                    ],
                    confirmPassword: [
                        {required: true, message: '请输入', trigger: 'blur'},
                    ],
                },
                //验证码图片url
                codeUrl: "",
            }
        },
        methods: {

            //注销
            submit() {
                this.$refs.ruleForm.validate((valid) => {
                    if (valid) {
                        this.$confirm('是否确认修改密码？', '提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(() => {
                            this.$HttpService.post("/sys-user/updatePassword", this.ruleForm).then(()=>{
                                this.$message.success('操作成功');
                                this.dialogVisible=false;
                            })
                        })

                    }
                });

            },

            /**
             * 打开弹框
             */
            openDia() {
                this.dialogVisible = true;
            },

            handleClose(done) {
                done();
            }
        }
    }

</script>

<style scoped>

</style>