<template>
  <!--同意弹框-->
  <el-dialog
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose">
      <span class="warn">
        严禁上传违法违规内容，包括但不限于:
        <span class="warnRed">色情低俗、政治敏感、VPN、暴力、恶意软件、诈骗、贩卖公民隐私等。</span>
        上传上述内容将被
        <span class="warnRed">永久封禁账号或IP 地址</span>
        ，并可能
        <span>承担刑事责任</span>。
      </span>
    <span slot="footer" class="dialog-footer">
        <el-button type="primary">同意并继续</el-button>
        <el-button @click.stop="dialogVisible=false">放弃上传文件</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  name: "agreeDia",
  data() {
    return {
      dialogVisible: false
    }
  },
  methods: {
    /**
     * 打开弹框
     */
    openDia() {
      this.dialogVisible = true;
    },

    handleClose(done) {
      done();
    }
  }
}
</script>
<style scoped>
/deep/ .el-dialog__header {
  display: none;
}
/deep/ .el-dialog__body{
  background: initial;
  text-align: initial;
  width: initial;
  height: initial;
  line-height: initial;
  border-radius: initial;
  margin-top: initial;
  cursor: initial;
  box-shadow: initial;
  transition: initial;
}

.warn{
  font-size: 15px;
  font-weight: 600;
  .warnRed {
    color: #eb0641;
  }
}

</style>