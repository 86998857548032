<template>
  <!--顶部菜单-->
  <div class="top_menu">
    <div :class="$parent.menuState?'titles':'title'">
      <span v-if="!$parent.menuState">HT文件管理系统</span>
      <span v-else>HT</span>
    </div>
    <ul>
      <li class="li_left">
        <i :class="$parent.menuState?'el-icon-s-unfold':'el-icon-s-fold'"
           @click="$parent.menuState=!$parent.menuState"></i>
      </li>
      <li>
        <user-pull-down>
          <span style="color: white">   {{ userInfo.name }}<i class="el-icon-arrow-down el-icon--right"></i></span>
        </user-pull-down>

      </li>
    </ul>

  </div>
</template>

<script>
import * as c from '@/util/config'
import * as u from '@/util/util'

export default {
  name: "index",
  components: {
    'user-pull-down': () => import('@/components/auth/userPullDown.vue'),
  },
  data() {
    return {
      //用户信息
      userInfo: u.getSession(c.sessionKey.USER_INFO),
    }
  },
  methods: {

  }
}
</script>

<style scoped lang="scss">
.top_menu {
  width: 100%;
  height: 50px;
  display: flex;
  background: #334157;

  .title {
    width: 200px;
    text-align: center;
    font-size: 20px;
    color: white;
    line-height: 50px;
    cursor: pointer;
  }

  .titles {
    width: 65px;
    text-align: center;
    font-size: 20px;
    color: white;
    line-height: 50px;
    cursor: pointer;
  }

  ul {
    flex: 1;
    width: 100%;
    height: 100%;
    padding: 0 20px;
    color: #fff;
    list-style-type: none;
    text-align: right;
  }

  .li_left {
    float: left;
    margin-left: 0px;
    font-size: 20px;
  }

  .el-icon-s-fold:hover, .el-icon-s-unfold:hover {
    color: #169bd5;
  }

  li {
    line-height: 50px;
    margin-left: 20px;
  }
}
</style>
