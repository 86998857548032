<template>
  <div class="box main" :style="style">
    <div class="error">
      <span class="error-con">哎呀，你访问的页面不存在！</span>
      <span>你输入的网址有误或链接已经过期。 </span>
      <span class="error-home"><a href="#" @click="goBack()">返回首页</a> </span>
    </div>
  </div>
</template>

<script>

    export default {
        name: "404",
        data() {
            return {
                style: {
                    backgroundImage: 'url(' + require('@/assets/bgerror.png') + ')',
                    backgroundRepeat: 'no-repeat',   //不重复
                    backgroundPosition: "center"
                }
            }
        },
        methods:{
            goBack(){
                this.$router.push(this.$config.HOME_PAGE.path);
            }
        }
    }
</script>

<style scoped>


  .box {
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
    position: relative
  }

  .main .error {
    width: 100%;
    padding: 40% 0 50px;
    text-align: center;
    color: #29AAE3
  }

  .main .error span {
    display: block;
    font-size: 16px;
    margin: 5px 0
  }

  .main .error span.error-con {
    font-size: 24px
  }

  .main .error span.error-home {
    font-size: 24px;
    font-weight: 800;
    margin: 10px 0
  }

  .main .error a {
    color: #29AAE3
  }
</style>
