<template>
  <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="20%"
      :before-close="handleClose"
      append-to-body>
    <el-tree
        default-expand-all
        :expand-on-click-node="false"
        :data="tree"
        node-key="id"
        ref="tree"
        highlight-current
        :props="defaultProps">
    </el-tree>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="confirm()">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: "folderTree",
  props: {
    //自定义弹框标题，配置不同功能
    title: {
      type: String,
      default: "文件夹树"
    },
  },
  data() {
    return {
      dialogVisible: false,
      tree: null,
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      //确认回调方法
      fun:null,
    }
  },
  methods: {

    /**
     * 打开
     */
    openDia(tree,fun) {
      this.tree = tree;
      this.fun=fun;
      this.dialogVisible = true;
    },


    handleClose(done) {
      done();
    },

    //确认方法
    confirm() {
      let node = this.$refs.tree.getCurrentNode();
      this.$emit("confirm",node);
      //判断是否执行确认回调方法
      if(this.fun){
        this.fun(node);
      }
      this.dialogVisible=false;
    }
  }
}
</script>

<style scoped>

</style>