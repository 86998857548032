<template>
  <!--右键tab页组件-->
  <div v-show="show" :style="{left:left+'px',top:top+'px'}" class="contextmenu">
    <ul>
      <li v-if="info==null" @click="operation('addFolder')">
        <el-button type="text" size="mini">新增文件夹</el-button>
      </li>
      <li v-if="info!=null" @click="operation('rename')">
        <el-button type="text" size="mini">重命名</el-button>
      </li>
      <li v-if="info==null" @click="operation('uploadFile')">
        <el-button type="text" size="mini">上传文件</el-button>
      </li>
      <li v-if="info!=null && info.type==='file'" @click="operation('downloadFile')">
        <el-button type="text" size="mini">下载文件</el-button>
      </li>
      <li v-if="info!=null && info.type==='file' && $config.supportFile.view.indexOf(info.fileInfo.fileType)!==-1"
          @click="operation('view')">
        <el-button type="text" size="mini">在线预览</el-button>
      </li>
      <li v-if="info!=null && info.type==='file' && $config.supportFile.edit.indexOf(info.fileInfo.fileType)!==-1"
          @click="operation('edit')">
        <el-button type="text" size="mini">在线编辑</el-button>
      </li>
      <li v-if="info!=null && info.type==='file'" @click="operation('move')">
        <el-button type="text" size="mini">移动文件</el-button>
      </li>
      <li v-if="info!=null && info.type==='file'" @click="operation('copy')">
        <el-button type="text" size="mini">复制文件</el-button>
      </li>
      <li v-if="info!=null && info.type==='file' && $config.supportFile.decompression.indexOf(info.fileInfo.fileType)!==-1"
          @click="operation('decompression')">
        <el-button type="text" size="mini">解压文件</el-button>
      </li>
      <li v-if="info!=null && info.type==='file' && $config.supportFile.toPdf.indexOf(info.fileInfo.fileType)!==-1"
          @click="operation('toPDF')">
        <el-button type="text" size="mini">转PDF</el-button>
      </li>

      <li v-if="info!=null" @click="operation('delete')">
        <el-button type="text" size="mini">删除</el-button>
      </li>
    </ul>
    <!--上传按键-->
    <input :key="fileInputKey" type="file" ref="fileInput" @change="onFileChange" style="display: none;"/>
    <folderTree ref="folderTree" :title="folderTreeTitle"></folderTree>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
import {getDownloadFileUrl, getUrlAll, uploadFile} from "@/util/util";
import * as u from "@/util/util";
import {supportFile} from "@/util/config";

export default {
  name: "fileTab",
  props: {
    left: Number,
    top: Number,
    //当前选中的菜单
    menuId: String,
    pid: String,
  },
  components: {
    'folderTree': () => import('@/components/folder/folderTree.vue'),
  },
  data() {
    return {
      //显示标识
      show: false,
      //当前选中的数据
      info: null,
      //选中的文件
      selectedFile: null,
      //上传组件key
      fileInputKey: 0,
      //翻译
      typeMap: {
        file: "文件",
        folder: "文件夹",
      },
      //文件夹树
      folderTree: [{
        name: "首页",
        folderId: "0",
        children: [],
      }],
      //文件树标题
      folderTreeTitle: "解压到",
    }
  },
  mounted() {
    window.onclick = res => {
      this.offDia();
    }
  },

  methods: {


    //操作
    operation(code) {
      switch (code) {
        case "addFolder":
          this.$prompt('请输入文件夹名称', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            inputValidator: val => {
              if (val.length > 20) {
                return "文件名长度不能大于20";
              }
              return true;
            },
          }).then(({value}) => {
            this.$HttpService.post("/file-folder/add", {
              parentId: this.pid,
              folderName: value,
            }).then(res => {
              this.$emit("ok");
            })
          })
          break;
        case "rename":
          this.$prompt('请输入文件夹名称', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            inputValue: this.info.name,
            inputValidator: val => {
              if (val.length > 20) {
                return "文件名长度不能大于20";
              }
              return true;
            },
          }).then(({value}) => {
            //判断类型
            if (this.info.type === 'file') {
              //针对文件重命名
              this.$HttpService.put("/file-info/update", {
                fileId: this.info.id,
                fileName: value,
              }).then(res => {
                this.$emit("ok");
              })

            }
            if (this.info.type === 'folder') {
              //针对文件夹重命名
              this.$HttpService.put("/file-folder/update", {
                folderId: this.info.id,
                folderName: value,
              }).then(res => {
                this.$emit("ok");
              })
            }
          })
          break;
        case "uploadFile":
          this.$refs.fileInput.click();
          break
        case "downloadFile":
          window.location.href = getDownloadFileUrl(this.info.id)
          break;
        case "view":
          //浏览器预览类型
          if (this.$config.supportFile.windowView.indexOf(this.info.fileInfo.fileType) !== -1) {
            window.open(this.$util.getOpenFileUrl(this.info.id));
          } else {
            window.open(this.$util.getUrlAll(`/file-info/viewFile/${this.info.id}`))
          }
          break;
        case "edit":
          window.open(this.$util.getUrlAll(`/file-info/editFile/${this.info.id}`))
          break;
        case "delete":
          this.$confirm(`是否确认删除${this.typeMap[this.info.type]}:${this.info.name}`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            //判断类型
            if (this.info.type === 'file') {
              this.$HttpService.del(`/file-info/delFile/${this.info.id}`).then(() => {
                this.$emit("ok");
              })
            }
            if (this.info.type === 'folder') {
              this.$HttpService.del(`/file-folder/delFolder/${this.info.id}`).then(() => {
                this.$emit("ok");
              })
            }
          }).catch(() => {
          })
          break;
        case "decompression":
          this.$HttpService.get("/file-folder/findFolderTreeByLogin").then(tree => {
            this.folderTreeTitle = "解压到"
            this.folderTree[0].children = tree;
            this.$refs.folderTree.openDia(this.folderTree, this.decompressionConfim);
          })
          break
        case "toPDF":
          this.$parent.loading = true;
          this.$HttpService.get(`/file-info/fileToPdf/${this.info.id}`).then(() => {
            this.$emit("ok");
            this.$parent.loading = false;
          }).catch(() => {
            this.$parent.loading = false;
          })
          break;
        case "copy":
          this.$HttpService.get("/file-folder/findFolderTreeByLogin").then(tree => {
            this.folderTreeTitle = "复制到"
            this.folderTree[0].children = tree;
            this.$refs.folderTree.openDia(this.folderTree, this.copyConfim);
          })
          break;
        case "move":
          this.$HttpService.get("/file-folder/findFolderTreeByLogin").then(tree => {
            this.folderTreeTitle = "移动到"
            this.folderTree[0].children = tree;
            this.$refs.folderTree.openDia(this.folderTree, this.moveConfim);
          })
          break;
      }
      this.show = false;
    },


    //文件变化监听
    onFileChange(event) {
      this.$parent.loading = true;
      this.selectedFile = event.target.files[0];
      //开始上传
      const formData = new FormData();
      formData.append('file', this.selectedFile);
      formData.append('folderId', this.pid);
      this.$HttpService.uploadFile(formData).then(() => {
        this.fileInputKey++;
        //刷新表格
        this.$emit("ok");
        this.$parent.loading = false;
      }).catch(() => {
        this.fileInputKey++;
        this.$parent.loading = false;
      })

    },

    /**
     * 打开当前弹框
     * @param info
     */
    openDia(info) {
      this.show = true;
      this.info = info;
    },
    /**
     * 关闭弹框
     * @param info
     */
    offDia() {
      this.show = false;
    },

    /**
     * 复制到确认事件回调
     * @param node
     */
    copyConfim(node) {
      this.$HttpService.post("/file-info/copyFile", {
        fileId: this.info.id,
        folderId: node.folderId
      }).then(() => {
        //知道路径节点集合
        let nodes = this.$util.findTreePath({id: "folderId", children: "children"}, this.folderTree, node.folderId)
        this.$emit("copyOk", {
          node: node,
          path: nodes
        });
        this.offDia();
      })
    },

    /**
     * 解压到确认事件回调
     * @param node
     */
    decompressionConfim(node) {
      this.$HttpService.post("/file-info/decompression", {
        fileId: this.info.id,
        folderId: node.folderId
      }).then(() => {
        //知道路径节点集合
        let nodes = this.$util.findTreePath({id: "folderId", children: "children"}, this.folderTree, node.folderId)
        this.$emit("decompressionOk", {
          node: node,
          path: nodes
        });
        this.offDia();
      })
    },

    /**
     * 移动到确认事件回调
     * @param node
     */
    moveConfim(node) {
      this.$HttpService.post("/file-info/moveFile", {
        fileId: this.info.id,
        folderId: node.folderId
      }).then(() => {
        //知道路径节点集合
        let nodes = this.$util.findTreePath({id: "folderId", children: "children"}, this.folderTree, node.folderId)
        this.$emit("moveOk", {
          node: node,
          path: nodes
        });
        this.offDia();
      })
    },

  }

}
</script>

<style scoped>
.contextmenu {
  width: 100px;
  margin: 0;
  border: 1px solid #ccc;
  background: #fff;
  z-index: 3000;
  position: fixed;
  list-style-type: none;
  padding: 5px 0;
  border-radius: 4px;
  font-size: 14px;
  color: #333;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.2);
  ul{
    list-style-type: none;
  }
  li {
    margin: 0;
    padding: 0px 22px;

  }
  li:hover {
    background: #f2f2f2;
    cursor: pointer;
  }
  li button {
    color: #2c3e50;
  }
}
</style>
